body {
  background-color: rgb(42, 42, 46);
}

.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected {
  font-weight: bold;
}

.flex-container {
  display: flex;
  margin-top: 30px;
  padding: 10px;
  justify-content: center;
  /* background-color: rgba(102, 119, 136, 0.3); */
}

.results {
  flex-direction: column;
  flex-wrap: wrap;
}

.sections {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.results {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  margin: auto;
  width: 80%;
  max-width: 80%;
}

.results a:hover {
  font-weight: bold;
  flex-wrap: wrap;
}

.result img {
  width: 100%;
}

#query {
  font-weight: bold;
  color: blue;
}

pre {
  border: none;
  border-radius: 2px;
  overflow: hidden;
  overflow-x: auto;
  background: #fff;
  font-size: 0.96em;
  line-height: 1.5;
  box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03),
    0 1px 2px rgba(102, 119, 136, 0.3), 0 8px 12px rgba(58, 193, 203, 0.1);
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  white-space: pre;
  background-color: #f8f8f8f8;
}

form {
  justify-content: center;
}

input[type='text'] {
  width: 40em;
  height: 1.2em;
  border-radius: 5px;
  padding: 10px 32px 10px 10px;
}

a:focus {
  background-color: yellow;
}

/* The Modal (background) */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 90%; /* Full height */
  /* overflow: auto; */
  overflow: scroll; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 0px 20px 20px 20px;
  border: 1px solid #888;
  width: 60em;
  max-width: 75%;
}

/* The Close Button */
.close-button {
  color: #aaaaaa;
  float: right;
  font-size: 1.2em;
  padding: .2em .5em;
  margin-top: 15px;
  font: Arial;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

pre > p {
  margin-left: 10px;
  color: #9d0a0e;
  font-weight: bold;
}

.battle-form {
  display: flex;
  justify-content: space-evenly;
}

.battle-form input {
  height: 2.5em;
  margin: 0.8em 0.2em;
  width: 20%;
}

.battle-form select {
  height: 2.5em;
  margin: 0.8em 0.2em;
  color: gray;
}

.battle-form select[value="Blue"] {
  color: blue;
}

.battle-form button {
  margin: 0.2em;
  height: 2.5em;
  align-self: center;
}

.battle-table {
  color: white;
  width: 100%;
}

.battle-reset {
  display: flex;
}

.battle-reset button {
  margin: 1em auto;
  color: red;
}

nav {
  display: flex;
  flex-direction: row;
}

nav > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  display: inline;
  margin-right: 1em;
  margin-left: 0.2em;
}

.nav-item > a {
  color: white;
}

.nav-item > a:focus {
  color: red;
}

#query-field {
  margin-top: 1em;
  /* background-color: rgb(56, 56, 61);
  box-sizing: border-box;
  color: rgb(249, 249, 250);
  cursor: text;
  box-shadow: rgba(12, 12, 13, 0.2); */
}

#battleground {
  background-color: darkgray;
  flex-direction: column;
  /* align-items: start; */
  width: 50%;
  height: 20em;
  margin: 1.05em auto auto auto;
  -moz-box-shadow: inset 0 0 0.4em #000000;
  -webkit-box-shadow: inset 0 0 0.4em #000000;
  box-shadow: inset 0 0 0.4em #000000;
}

.avatar {
  display: block;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  color: white;
  text-align: center;
  cursor: crosshair;
  box-sizing: border-box;
}

.avatar:hover {
  border: 0.1em solid white;
}

.avatar-red {
  background-color: darkred;
  float: right;
  border: 0.1em solid darkred;
}

.avatar-blue {
  background-color: darkblue;
  float: left;
  border: 0.1em solid darkblue;
}

.avatar-label {
  margin-top: 0.2em;
  cursor: crosshair;
}

.avatar-label:hover + .avatar-petit-nom {
  display: block;
}

.avatar-petit-nom {
  display: none;
}

.holder {
  position: relative;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
  position: absolute;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
  position: absolute;
}

.suggestions li {
  padding: 0.5rem;
  background-color: rgb(82, 82, 90);
  color: white;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

#creature {
  width: auto;
}

#popout-checkbox {
  color: white;
  padding: 1em 0em 0em 0.2em;
}

#popout-checkbox label {
  margin-left: 0.2em;
}

#creature-layer {
  position: relative;
  z-index: 10000;
}
#loader {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 3em 0em 1.5em 0em;
}